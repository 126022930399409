var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "filter-report my-5" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text--primary" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.establishment,
                          items: _vm.companies,
                          "item-text": "socialName",
                          "item-value": "cnpjMatriz",
                          label: "Empresas",
                          placeholder: "Todos",
                          "no-data-text": "Selecione uma empresa primeiro",
                          dense: "",
                          outlined: "",
                          multiple: _vm.multiple,
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "append-icon": "mdi-chevron-down",
                          "persistent-placeholder": "",
                        },
                        on: { input: (items) => _vm.emitirRelatorio(items) },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item, attrs, on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: { "pl-10": item.group },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.socialName
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.stateCode ||
                                                                  "Sem dado"
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.city ||
                                                                  "Sem dado"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }