<template>
  <div id="cardRelatorio">
    <h2 class="mt-2 titleColor--text">Relatórios</h2>

    <v-col>
      <FilterForRelatorios />
      <reportsTable class="mt-5" />
    </v-col>
  </div>
</template>
<script>
import FilterForRelatorios from './components/FilterForRelatorios.vue';
export default {
  name: 'Relatórios',
  components: {
    FilterForRelatorios,
    reportsTable: () => import('./components/EsocialRemunReportsTable.vue'),
  },
};
</script>
<style scoped>
#cardRelatorio {
  margin-top: 100px;
}
</style>
