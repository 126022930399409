var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "cardRelatorio" } },
    [
      _c("h2", { staticClass: "mt-2 titleColor--text" }, [
        _vm._v("Relatórios"),
      ]),
      _c(
        "v-col",
        [
          _c("FilterForRelatorios"),
          _c("reportsTable", { staticClass: "mt-5" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }