<template>
  <v-container class="filter-report my-5">
    <v-row>
      <v-col>
        <v-card>
          <v-card-text class="text--primary">
            <v-autocomplete
              :value="establishment"
              :items="companies"
              item-text="socialName"
              item-value="cnpjMatriz"
              label="Empresas"
              placeholder="Todos"
              no-data-text="Selecione uma empresa primeiro"
              dense
              outlined
              :multiple="multiple"
              rounded
              clearable
              hide-details
              append-icon="mdi-chevron-down"
              persistent-placeholder
              @input="(items) => emitirRelatorio(items)"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.socialName"></v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.stateCode || 'Sem dado' }} - {{ item.city || 'Sem dado' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EsocialService from '@/services/eSocialService.js';
export default {
  name: 'FilterForRelatorios',
  data() {
    return {
      multiple: false,
      filterParam: {},
      cnpjsMatriz: [],
      itemsEstabelecimentos: [],
      establishment: '',
    };
  },

  computed: {
    ...mapState('dashboard', {
      companies: (state) => state.companies,
      establishments: (state) => state.establishments,
    }),
  },
  methods: {
    ...mapActions('dashboard', ['fetchCompanies']),

    async emitirRelatorio(cnpj) {
      if (cnpj) {
        await EsocialService.report.generate(cnpj);
      }
    },
  },
  created() {
    this.fetchCompanies();
  },
};
</script>
<style scoped>
.cardEspecifico {
  margin-top: 100px;
}
.filter-report {
  width: 101%;
  max-width: 106%;
  margin-left: -22px;
}
</style>
